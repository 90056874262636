import React from 'react';
import './contato.css';

// Rotas
import BannerHotel from '../../components/BannerHotel';
import Formulario from '../../components/Formulario';

// Imagens
import Banner from '../../assets/BannerContato.webp';
import homeContato from '../../assets/homeContato.png';

export default function Contact() {
  return (
    <>
      {window.innerWidth >= 769 ? (
        <>
          <BannerHotel banner={Banner} titulo={'Contato'} />
          <div className='contato-espacamento-form'></div>
          <Formulario />
        </>
      ) : (
        <div className='flex items-center flex-col pt-11'>
          <img className='w-full' src={homeContato} alt='Banner' />
          <p className='text-sm text-black font-extrabold font-roboto-flex pt-5 min-[768px]:text-xl'>
            Contato
          </p>
          <Formulario />
          <div />
        </div>
      )}
    </>
  );
}

