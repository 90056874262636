import React from 'react';

const LandingContent = ({ direction, Imagem, TextTittle, TextLabel }) => {
  // Determina se a imagem deve ser exibida primeiro ou não
  const isRight = direction === 'right';

  return (
    <div className='flex flex-row w-full'>
      {isRight ? (
        <>
          <div className='flex flex-col pl-3 pt-6 min-[768px]:ml-28'>
            <p className='text-sm text-black font-bold font-roboto-flex pt-4 min-[768px]:text-xl'>
              {TextTittle}
            </p>
            <p className='text-xs text-black font-roboto-flex font-medium min-[768px]:text-sm'>
              {TextLabel}
            </p>
          </div>
          <div className='min-w-4 mt-3 mb-3' style={{ backgroundColor: '#45454D' }}></div>
          <img className='max-w-40 min-h-36 min-[425px]:min-w-52' src={Imagem} alt="Landing" />
        </>
      ) : (
        <>
          <img className='max-w-40 min-h-36 min-[425px]:min-w-52' src={Imagem} alt="Landing" />
          <div className='min-w-4 mt-3 mb-3' style={{ backgroundColor: '#45454D' }}></div>
          <div className='flex flex-col pl-3 pt-6'>
            <p className='text-sm text-black font-bold font-roboto-flex pt-4 min-[768px]:text-xl'>
              {TextTittle}
            </p>
            <p className='text-xs text-black font-roboto-flex font-medium min-[768px]:text-sm'>
              {TextLabel}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default LandingContent;
