import React, { useState } from 'react';
import './Lazer.css';

// Rotas
import BannerHotel from '../../components/BannerHotel';
import RestauranteImg from '../../assets/RestauranteLeCanardi.webp';
import Hero from '../../components/Hero';
import Carousel from '../../components/Carousel/Carrousel';
import CafedaManha from '../../components/CafedaManha';
import SliderText from '../../components/Carousel/SliderText';
import CoffeShop from '../../components/CoffeShop';
import homeLazer from '../../assets/homeLazer.png';
import coffeShop from '../../assets/coffeShop.svg';
import academia from '../../assets/academia.svg';
import cafedaManha from '../../assets/cafedaManha.svg';
import internetLazer from '../../assets/internetLazer.svg';

// Imagens
import Banner from '../../assets/BannerLazer.webp';

const Lazer = () => {
  const InfoLazerSeo = {
    titulo: 'Lazer',
    tituloCarousel: 'Academia',
    descricaoCarousel:
      'Eleita pela CVB como a melhor academia, com funcionamento 24h.',
  };

  const windowWidth = useState(window.innerWidth);

  return (
    <>
      {windowWidth >= 769 ? (
        <>
          <BannerHotel titulo={`${InfoLazerSeo.titulo}`} banner={Banner} />
          <div className='lazer-espacamento-hero'></div>
          <Hero />
          <div className='lazer-espacamento-carousel'></div>
          <Carousel>
            <div className='Lazer-carrousel01'>
              <SliderText
                titulo={`${InfoLazerSeo.tituloCarousel}`}
                info={`${InfoLazerSeo.descricaoCarousel}`}
              />
            </div>
            <div className='Lazer-carrousel02'>
              <SliderText
                titulo={`${InfoLazerSeo.tituloCarousel}`}
                info={`${InfoLazerSeo.descricaoCarousel}`}
              />
            </div>
            <div className='Lazer-carrousel03'>
              <SliderText
                titulo={`${InfoLazerSeo.tituloCarousel}`}
                info={`${InfoLazerSeo.descricaoCarousel}`}
              />
            </div>
          </Carousel>
          <div className='lazer-espacamento-carousel'></div>
          <CafedaManha imgSrc={RestauranteImg} />
          <CoffeShop />
        </>
      ) : (
        <div className='flex items-center flex-col'>
          <img className='w-full' src={homeLazer} alt='Banner' />
          <div className='flex flex-col items-center text-center min-w-64 max-w-64 pt-2 pb-2'>
            <p className='text-sm text-black font-extrabold font-roboto-flex min-[768px]:text-xl'>
              Lazer
            </p>
          </div>

          <div className='flex flex-row gap-3 max-w-16 items-center justify-center mb-2  min-[768px]:max-w-32'>
            <img src={academia} alt='Banner' />
            <img src={internetLazer} alt='Banner' />
            <img src={cafedaManha} alt='Banner' />
            <img src={coffeShop} alt='Banner' />
          </div>
        </div>
      )}
    </>
  );
};

export default Lazer;
