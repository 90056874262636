import React from 'react';
import './Cliente.css';

// Rotas
import BannerHotel from '../../components/BannerHotel';
import HotelLink from '../../components/HotelLink';
import Cliente from '../../components/Cliente';
import homeCliente from '../../assets/homeCliente.png';
import tarifaImg from '../../assets/tarifaImg.svg';
import apenasImg from '../../assets/apenasImg.svg';

import LocationsCards from '../../components/locationCards/index.js';

// Imagens
import Banner from '../../assets/BannerCliente.webp';

const ClientePage = () => {
  const InfoCliente = {
    titulo: 'Cliente Habitue',
    tituloHabitue: 'Cliente Habitue',
    describeHabitue:
      'O Le Canard hotéis possui um tipo especial de cliente, o Habitue. Essa categoria foi criada pensando nos hóspedes que se hospedam diversas vezes, que garantem assim vantagens e benefícios exclusivos.',
    subTiulo01: 'Tarifa diferenciada',
    subTitulo02: 'Apenas 1 tarifa',
    describeSub01:
      'Quanto mais room nights utilizadas, menor a tarifa no decorrer do tempo',
    describeSub02: 'É cobrada apenas o ICMS, por ser uma obrigatório.',
  };

  return (
    <>
      {window.innerWidth >= 769 ? (
        <>
          <BannerHotel banner={Banner} titulo={InfoCliente.titulo} />
          <div className='cliente-espacamento-habitue'></div>
          <Cliente
            titulo={InfoCliente.tituloHabitue}
            descricao={InfoCliente.describeHabitue}
            subTitleOne={InfoCliente.subTiulo01}
            subTitleTwo={InfoCliente.subTitulo02}
            describeSubOne={InfoCliente.describeSub01}
            describeSubTwo={InfoCliente.describeSub02}
          />
          <div className='cliente-espacamento-habitue-bottom'></div>
          <HotelLink />
        </>
      ) : (
        <div className='flex items-center flex-col pt-11'>
          <img className='w-full' src={homeCliente} alt='Banner' />
          <div className='flex flex-col items-center text-center min-w-64 max-w-64 pt-2 pb-2'>
            <p className='text-sm text-black font-extrabold font-roboto-flex min-[768px]:text-xl'>
              Cliente Habitue
            </p>
            <p className='text-xs text-black font-semibold font-roboto-flex min-[768px]:max-w-xl'>
              O Le Canard hotéis possui um tipo especial de cliente, o Habitue.
              Essa categoria foi criada pensando nos hóspedes que se hospedam
              diversas vezes, que garantem assim vantagens e benefícios
              exclusivos.
            </p>
          </div>

          <div className='flex flex-row gap-3 max-w-28 items-center justify-center mb-2  min-[768px]:max-w-32'>
            <img src={tarifaImg} alt='Banner' />
            <img src={apenasImg} alt='Banner' />
          </div>

          <LocationsCards />
        </div>
      )}
    </>
  );
};

export default ClientePage;
