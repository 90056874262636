import React, { useState } from 'react';
import { MdBed, MdOutlineDirectionsCarFilled } from 'react-icons/md';
import { GiMoneyStack, GiCoffeeCup } from 'react-icons/gi';
import { IoMdWoman } from 'react-icons/io';
import { BiMap } from 'react-icons/bi';
import './styles.css';
import { CiDumbbell } from "react-icons/ci";
import { LiaHotelSolid } from "react-icons/lia";
import { FaCar } from "react-icons/fa";


import Carousel from '../../components/Carousel/Carrousel';
import Slider from '../../components/Carousel/Slider';
import HotelLink from '../../components/HotelLink';
import LandingContent from '../../components/hotelLanding/landingContent';
import LocationsCards from '../../components/locationCards';
import ActionBox from '../../components/PopUpMobile';

import HomeSmart from '../../assets/home_smart.webp';
import Slider1 from '../../assets/home_slider1.webp';
import Slider2 from '../../assets/home_slider2.webp';
import Slider3 from '../../assets/home_slider3.webp';
import Slider4 from '../../assets/home_slider4.webp';
import Slider5 from '../../assets/home_slider5.webp';
import Slider6 from '../../assets/home_slider6.webp';
import Slider7 from '../../assets/home_slider7.webp';
import homeImg from '../../assets/homeImg.png';
import homeCarrousel1 from '../../assets/homeCarrousel1.png';

export default function Home() {

  const backgroundColor = '#45454D';

  const cardClassName = `
  max-w-24 max-h-36 min-h-36
  min-[375px]:min-w-28 
  min-[425px]:min-w-32 min-[425px]:min-h-36
  min-[768px]:min-w-56 min-[768px]:min-h-52
  rounded-md flex items-center justify-center text-center
  flex-col break-words
`;

  const iconClassName = `
  text-4xl 
  min-[768px]:text-7xl
  ml-2 mr-2
`;

  const titleClassName = `
  text-xs
  min-[768px]:text-sm
  text-white font-bold ml-4 mt-2 font-roboto-flex mr-4
`;

  const descClassName = `
  text-xs
  min-[768px]:text-sm
  text-white ml-4 mt-2 font-roboto-flex mr-4
`;

  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };


  return (
    <>
      {window.innerWidth >= 769 ? (
        <div>

          <div className="carroussel-container">
            <Carousel>
              <div className="carrousel-img-container">
                <img
                  className="carroussel-item"
                  src={Slider1}
                  alt="Hotel Le Canard"
                />
              </div>

              <Slider Imagem={Slider2} texto={'Localização privilegiada'} />
              <Slider Imagem={Slider3} texto={'Alimentação completa'} />
              <Slider Imagem={Slider4} texto={'Academia 24 horas'} />
              <Slider Imagem={Slider5} texto={'Quartos conjugados'} />
              <Slider Imagem={Slider6} texto={'Ala feminina exclusiva'} />
              <Slider Imagem={Slider7} texto={'Espaço ideal para eventos'} />
            </Carousel>
          </div>

          <div className="smart-container">
            <div className="smart-item">
              <h1 className="smart-item-title">Escolha inteligente</h1>
              <p className="smart-item-text">
                Design urbano e contemporâneo, ambiente calmo, quartos inteligentes
                e confortáveis. O melhor custo-benefício você só encontra aqui!
              </p>
            </div>
            <img
              className="smart-item-image"
              src={HomeSmart}
              alt="Hotel Le Canard"
            />
          </div>

          <div className="benefits-container">
            <div className="double-item">
              <div className="benefits-item">
                <MdBed className="benefits-item-icon" />
                <h3 className="benefits-item-title">Quartos novos e acolhedores</h3>
                <p className="benefits-item-text">
                  Limpeza e cuidados pensando sempre no melhor para o cliente
                </p>
              </div>

              <div className="benefits-item">
                <GiMoneyStack className="benefits-item-icon" />
                <h3 className="benefits-item-title">Melhor Custo x Benefício</h3>
                <p className="benefits-item-text">
                  Preços únicos atrelados a vantagens e comodidades especiais
                </p>
              </div>
            </div>

            <div className="double-item">
              <div className="benefits-item">
                <GiCoffeeCup className="benefits-item-icon" />
                <h3 className="benefits-item-title">Alimentação completa</h3>
                <p className="benefits-item-text">
                  Café da manhã completo, assim como restaurante preparado
                </p>
              </div>

              <div className="benefits-item">
                <MdOutlineDirectionsCarFilled className="benefits-item-icon" />
                <h3 className="benefits-item-title">Estacionamento privativo</h3>
                <p className="benefits-item-text">
                  Estacionamento exclusivo para os clientes do Le Canard
                </p>
              </div>
            </div>

            <div className="double-item">
              <div className="benefits-item">
                <BiMap className="benefits-item-icon" />
                <h3 className="benefits-item-title">Localização privilegiada</h3>
                <p className="benefits-item-text">
                  Le Canard está situado perto dos principais pontos da cidade
                </p>
              </div>

              <div className="benefits-item">
                <IoMdWoman className="benefits-item-icon" />
                <h3 className="benefits-item-title">Ala feminina exclusiva</h3>
                <p className="benefits-item-text">
                  Todo um andar em que só mulheres podem se hospedar
                </p>
              </div>
            </div>
          </div>
          <HotelLink />
        </div>
      ) : (



        <div className='flex flex-col'>

          {isPopupVisible && (
            <div className="relative">
              <ActionBox
                onClose={handleClosePopup}
              />
            </div>
          )}

          <div className="carroussel-container">
            <Carousel>

              <div className="carrousel-img-container">
                <img
                  className="carroussel-item"
                  src={homeCarrousel1}
                  alt="Hotel Le Canard"
                />
              </div>

              <Slider Imagem={Slider2} texto={'Localização privilegiada'} />
              <Slider Imagem={Slider3} texto={'Alimentação completa'} />
              <Slider Imagem={Slider4} texto={'Academia 24 horas'} />
              <Slider Imagem={Slider5} texto={'Quartos conjugados'} />
              <Slider Imagem={Slider6} texto={'Ala feminina exclusiva'} />
              <Slider Imagem={Slider7} texto={'Espaço ideal para eventos'} />
            </Carousel>
          </div>

          <div className="flex justify-between mt-5 mb-5 pl-2 pr-2 min-[768px]:pl-9 min-[768px]:pr-9">
            <div
              style={{ backgroundColor }}
              className={cardClassName}
            >
              <CiDumbbell className={iconClassName} style={{ color: "#808181" }} />
              <p className={titleClassName}>Academia 24h</p>
              <p className={descClassName}>Contamos com uma academia bem equipada que funciona 24h</p>
            </div>
            <div
              style={{ backgroundColor }}
              className={cardClassName}
            >
              <LiaHotelSolid className={iconClassName} style={{ color: "#808181" }} />
              <p className={titleClassName}>Atendimento de excelência</p>
              <p className={descClassName}>O melhor atendimento corporativo para você</p>
            </div>
            <div
              style={{ backgroundColor }}
              className={cardClassName}
            >
              <FaCar className={iconClassName} style={{ color: "#808181" }} />
              <p className={titleClassName}>Atendimento de excelência</p>
              <p className={descClassName}>O melhor atendimento corporativo para você</p>
            </div>
          </div>


          <LandingContent direction="left" Imagem={homeImg} TextTittle={"Escolha inteligente"} TextLabel={"Design urbano e contemporâneo, ambiente calmo, quartos inteligentes e confortáveis. O melhor custo-benefício você só encontra aqui!"} />

          <LocationsCards />



        </div>

      )}
    </>
  );
}
