import React, { useState } from 'react';
import carrouselMobile1 from '../../assets/carrouselMobile1.png';
import carrouselMobile2 from '../../assets/carrouselMobile2.png';
import carrouselMobile3 from '../../assets/carrouselMobile3.png';

const images = [carrouselMobile1, carrouselMobile2, carrouselMobile3];
const textsTitle = [
    "Executivo Duplo",
    "Suíte Superior",
    "Executivo Twin",
];

const textsLabels = [
    "O Executivo Duplo conta com um ambiente aconchegante, no qual possui 1 cama box queen, TV LED 32 polegadas, frigobar abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.",
    "A Suíte Superior está situada em uma nova ala do hotel, contando com uma decoração moderna, televisão maior, melhor isolamento acústico, uma banheira com hidromassagem, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.",
    "A Suíte Superior está situada em uma nova ala do hotel, contando com uma decoração moderna, televisão maior, melhor isolamento acústico, uma banheira com hidromassagem, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.",
];

const CarrouselMobile = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const prevIndex = (activeIndex - 1 + images.length) % images.length;
    const nextIndex = (activeIndex + 1) % images.length;

    return (
        <div className="relative w-full max-w-full h-full flex flex-col items-center justify-center pt-3">
            <div className="relative w-full max-w-full h-full overflow-hidden flex items-center justify-center">
                {/* Botão de navegação anterior */}
                <button
                    onClick={handlePrev}
                    className="absolute left-2 z-30 bg-[#D9D9D9] text-white rounded-full w-[30px] h-[30px] flex items-center justify-center cursor-pointer border-none outline-none text-[18px] leading-[1]"
                    aria-label="Previous"
                >
                    &lt;
                </button>

                <div className="absolute z-20 min-h-36 min-w-64" style={{ backgroundColor: '#45454D' }} />

                {/* Div Secundária (Imagens ao Fundo) */}
                <div className="absolute inset-0 flex items-center justify-center">
                    <div
                        className="absolute flex items-center justify-center transition-transform duration-500 ease-in-out"
                        style={{
                            transform: `translateX(-100%)`,
                        }}
                    >
                        <img
                            src={images[prevIndex]}
                            alt={`Previous Image`}
                            className="w-[100px] h-[100px] object-cover transition-transform duration-500 ease-in-out transform scale-90 min-w-40 min-h-40 pr-12"
                        />
                    </div>
                    <div
                        className="absolute flex items-center justify-center transition-transform duration-500 ease-in-out"
                        style={{
                            transform: `translateX(100%)`,
                        }}
                    >
                        <img
                            src={images[nextIndex]}
                            alt={`Next Image`}
                            className="w-[100px] h-[100px] object-cover transition-transform duration-500 ease-in-out transform scale-90 min-w-40 min-h-40 pl-12"
                        />
                    </div>
                </div>

                {/* Div Principal (Imagem Ativa) - On top */}
                <div className="relative flex items-center justify-center w-full h-full overflow-hidden max-w-52 max-h-40">
                    {/* Imagem Ativa */}
                    <img
                        src={images[activeIndex]}
                        alt={`Image ${activeIndex + 1}`}
                        className="z-30 w-full h-full object-cover transition-transform duration-500 ease-in-out transform scale-110"
                    />
                </div>

                {/* Botão de navegação próximo */}
                <button
                    onClick={handleNext}
                    className="absolute right-2 z-30 bg-[#D9D9D9] text-white rounded-full w-[30px] h-[30px] flex items-center justify-center cursor-pointer border-none outline-none text-[18px] leading-[1]"
                    aria-label="Next"
                >
                    &gt;
                </button>
            </div>

            <div className="w-full text-black text-center pt-2 text-sm text-black font-bold font-roboto-flex">
                {textsTitle[activeIndex]}
            </div>

            <div className="w-full text-black text-center pt-2 pb-5 text-xs text-black font-roboto-flex max-w-64">
                {textsLabels[activeIndex]}
            </div>
        </div>
    );
};

export default CarrouselMobile;
