import React from 'react';
import { GiChart, GiTable, GiAmplitude } from 'react-icons/gi';
import { TbLicense, TbMicrophone } from 'react-icons/tb';
import { FaWifi, FaThinkPeaks } from 'react-icons/fa';
import './Eventos.css';
import { Link } from 'react-router-dom';

// Rotas
import BannerHotel from '../../components/BannerHotel';
import SalaL from '../../components/SalasDeEventos/SalaEventoLeft';
import SalaR from '../../components/SalasDeEventos/SalaEventoRight';
import GroupIcons from '../../components/GroupIcons';
import EnvIcone from '../../components/EnvIcone';
import CoffeBreak from '../../components/CoffeBreak';
import Formulario from '../../components/Formulario';
// Imagens
import Banner from '../../assets/BannerEventos.webp';

import eventosImg1 from '../../assets/eventosImg1.png';
import eventosImg2 from '../../assets/eventosImg2.png';

import tableEventos1 from '../../assets/tableEventos1.PNG';

import homeEventos from '../../assets/homeEventos.png';

import flipImg from '../../assets/flipImg.svg';
import telaImg from '../../assets/telaImg.svg';
import mesaImg from '../../assets/mesaImg.svg';
import microfoneImg from '../../assets/microfoneImg.svg';
import wifiImg from '../../assets/wifiImg.svg';
import caixaImg from '../../assets/flipImg.svg';
import eventosRandom from '../../assets/eventosRandom.png';

const Eventos = () => {
  const InfoEventos = {
    tituloPrincipal: 'Eventos',
    tituloSessao: 'Equipamentos',
    describeEquip: 'Equipamentos contratados a parte.',
  };

  const IconsInfoEvento = {
    info01: 'Flip Chart',
    info02: 'Tela de Projeção',
    info03: 'Mesas de Trabalho',
    info04: 'Porta Banner',
    info05: 'Wi-fi',
    info06: 'Caixa Amplificadora de Som',
    info07: 'Microfone',
  };

  return (
    <>
      {window.innerWidth >= 769 ? (
        <>
          <BannerHotel
            banner={Banner}
            titulo={`${InfoEventos.tituloPrincipal}`}
          />
          <div className='evento-espacamento-topo'></div>
          <SalaL />
          <SalaR />
          <div className='evento-titulo-sessao-equipamentos'>
            <h2>{`${InfoEventos.tituloSessao}`}</h2>
          </div>
          <GroupIcons>
            <EnvIcone
              informacaodoIcone={`${IconsInfoEvento.info01}`}
              NewIcons={<TbLicense />}
            />
            <EnvIcone
              informacaodoIcone={`${IconsInfoEvento.info02}`}
              NewIcons={<GiChart />}
            />
            <EnvIcone
              informacaodoIcone={`${IconsInfoEvento.info03}`}
              NewIcons={<GiTable />}
            />
            <EnvIcone
              informacaodoIcone={`${IconsInfoEvento.info04}`}
              NewIcons={<FaThinkPeaks />}
            />
          </GroupIcons>
          <GroupIcons>
            <EnvIcone
              informacaodoIcone={`${IconsInfoEvento.info05}`}
              NewIcons={<FaWifi />}
            />
            <EnvIcone
              informacaodoIcone={`${IconsInfoEvento.info06}`}
              NewIcons={<GiAmplitude />}
            />
            <EnvIcone
              informacaodoIcone={`${IconsInfoEvento.info07}`}
              NewIcons={<TbMicrophone />}
            />
          </GroupIcons>
          <p className='eventos-txt-equip'>{`${InfoEventos.describeEquip}`}</p>
          <CoffeBreak />
          <Formulario />
        </>
      ) : (
        <div className='flex items-center flex-col pt-11'>
          <img className='w-full' src={homeEventos} alt='Banner' />
          <div className='flex flex-col items-center text-center min-w-64 max-w-64 pt-2 pb-2'>
            <p className='text-sm text-black font-extrabold font-roboto-flex min-[768px]:text-xl'>
              Eventos
            </p>
            <p className='text-xs text-black font-semibold font-roboto-flex'>
              LeCanard possui o espaço ideal para o sucesso do seu evento.
            </p>
          </div>

          <div className='flex flex-row w-full'>
            <div className='flex flex-row w-full'>
              <img
                className='max-w-40 min-h-36 min-[425px]:min-w-52 min-[768px]:min-w-80'
                src={eventosImg1}
                alt='Landing'
              />
              <div
                className='min-w-4 mt-3 mb-3'
                style={{ backgroundColor: '#45454D' }}
              ></div>
            </div>

            <div className='flex flex-col items-center w-full pt-4 pl-2 min-[768px]:min-h-40 min-[768px]:max-w-80 min-[768px]:pr-14'>
              <img
                className='max-w-32 max-h-20 min-[768px]:min-h-40 min-[768px]:min-w-72 '
                src={tableEventos1}
                alt='Landing'
              />

              <Link
                to='/contato'
                alt='Entre em contato'
                className='btn-book w-full max-w-28 max-h-8 rounded-md cursor-pointer mt-3 text-xs text-black font-bold font-roboto-flex items-center'
                style={{ backgroundColor: '#0F1620' }}
              >
                Agende seu evento!
              </Link>
            </div>
          </div>

          <div className='flex flex-row w-full mt-4'>
            <div className='flex flex-col items-center w-full pt-4 '>
              <img
                className='max-w-32 max-h-20 min-[768px]:min-h-40 min-[768px]:min-w-72'
                src={tableEventos1}
                alt='Landing'
              />

              <Link
                to='/contato'
                alt='Entre em contato'
                className='btn-book w-full max-w-28 max-h-8 rounded-md cursor-pointer mt-3 text-xs text-black font-bold font-roboto-flex items-center'
                style={{ backgroundColor: '#0F1620' }}
              >
                Agende seu evento!
              </Link>
            </div>

            <div className='flex flex-row w-full'>
              <div
                className='min-w-4 mt-3 mb-3'
                style={{ backgroundColor: '#45454D' }}
              ></div>
              <img
                className='max-w-40 min-h-36 min-[425px]:min-w-52 min-[768px]:min-w-80'
                src={eventosImg2}
                alt='Landing'
              />
            </div>
          </div>

          <div className='flex flex-row gap-3 max-w-20 items-center justify-center mb-2  min-[768px]:max-w-32 pt-4'>
            <img src={flipImg} alt='Banner' />
            <img src={telaImg} alt='Banner' />
            <img src={mesaImg} alt='Banner' />
          </div>

          <div className='flex flex-row gap-3 max-w-20 items-center justify-center mb-2  min-[768px]:max-w-32'>
            <img src={microfoneImg} alt='Banner' />
            <img src={wifiImg} alt='Banner' />
            <img src={caixaImg} alt='Banner' />
          </div>

          <div className='flex flex-row w-full pt-4'>
            <img
              className='max-w-40 min-h-36 min-[425px]:min-w-52'
              src={eventosRandom}
              alt='Landing'
            />
            <div
              className='min-w-4 mt-3 mb-3'
              style={{ backgroundColor: '#45454D' }}
            ></div>
            <div className='flex flex-col pl-3 pt-5'>
              <p className='text-sm text-black font-bold font-roboto-flex '>
                Coffee Break
              </p>
              <p className='text-xs text-black font-roboto-flex font-medium min-[768px]:text-sm'>
                O menu do coffee break traz três opções de combinações sempre
                com a mais alta qualidade e o menu coquetel do Le Canard
                apresenta 2 opções com deliciosas combinações. A rede de hotéis
                Le Canard conta com restaurantes terceirizados que trazem em seu
                cardápio pratos com excelente qualidade.
              </p>
            </div>
          </div>
          <Formulario />
        </div>
      )}
    </>
  );
};

export default Eventos;
