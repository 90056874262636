import React from 'react';
import { useNavigate } from 'react-router-dom';
import locationImg from '../../assets/locationImg.png';
import locationImg2 from '../../assets/locationImg2.png';

const LocationsCards = () => {
    const navigate = useNavigate();

    const handleJoinville = () => {
        navigate('/joinville');
    };

    const handleLages = () => {
        navigate('/lages');
    };

    const divTextLavel = "text-black font-roboto-flex min-[768px]:text-sm";

    return (
        <div className='mb-3'>
            <p className='text-sm text-black font-bold font-roboto-flex pl-2 mt-4 mb-1 min-[768px]:text-xl'>Conheça nossos endereços</p>
            <div className='flex flex-row w-full justify-center gap-2 pr-1 pl-1'>
                <div className='flex flex-col rounded overflow-hidden items-center text-center shadow-sm min-w-36 pb-3'>
                    <img className='min-h-24' src={locationImg} alt="Banner Home"></img>
                    <p className='mt-2 text-sm text-black font-bold font-roboto-flex min-[768px]:text-xl'> Le Canard Joinville</p>
                    <div className="mt-2 text-xs text-black font-medium font-roboto-flex">
                        <p className={divTextLavel}>Avenida Presidente Vargas, 255</p>
                        <p className={divTextLavel}>Lages/SC</p>
                        <p className={divTextLavel}>Telefone: 49 99110-0244</p>
                        <p className={divTextLavel}>Email: lages@lecanard.com.br</p>
                    </div>
                    <div className='mt-3 btn-book w-full max-w-28 max-h-8 rounded-md cursor-pointer' onClick={handleJoinville} style={{ backgroundColor: '#0F1620' }}>
                        <div className='btn-book-text font-roboto-flex text-sm' >Saiba mais</div>
                    </div>
                </div>
                <div className='flex flex-col rounded overflow-hidden items-center text-center shadow-sm min-w-36 pb-3'>
                    <img className='min-h-24' src={locationImg2} alt="Banner Home"></img>
                    <p className='mt-2 text-sm text-black font-bold font-roboto-flex min-[768px]:text-xl'>Le Canard Lages</p>
                    <div className="mt-2 text-xs text-black font-medium font-roboto-flex">
                        <p className={divTextLavel}>Avenida Presidente Vargas, 255</p>
                        <p className={divTextLavel}>Lages/SC</p>
                        <p className={divTextLavel}>Telefone: 49 99110-0244</p>
                        <p className={divTextLavel}>Email: lages@lecanard.com.br</p>
                    </div>
                    <div className='btn-book w-full max-w-28 max-h-8 rounded-md cursor-pointer mt-3' onClick={handleLages} style={{ backgroundColor: '#0F1620' }}>
                        <div className='btn-book-text font-roboto-flex text-sm' >Saiba mais</div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default LocationsCards;
