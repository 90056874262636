import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import { Link } from 'react-router-dom';
import './style.css';
import { FiMail, FiPhone, FiMapPin } from 'react-icons/fi';

const Localizacao = ({
  endereco,
  email,
  telefone,
  rotaEmail,
  rotaTelefone,
  imgSrc,
  urlMaps,
}) => {
  const InfoEndereco = {
    titulo: 'Venha conhecer',
    BtnContato: 'Entrar em contato',
  };

  // Assume windowWidth is defined or should be managed as part of state or props
  const windowWidth = window.innerWidth;

  return (
    <>
      {windowWidth >= 769 ? (
        <section className='endereco-wrapper'>
          <div className='end-mapa-wrapper-left'>
            <div
              title='Clique para endereço'
              rel='noreferrer'
              alt='Localização Hotel - Le Canard'
            >
              <iframe
                src={urlMaps}
                className='mapa'
                allowFullScreen
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
                title='Maps-Trianon'
              />
            </div>
          </div>
          <div className='end-info-wrap-right'>
            <h2>{InfoEndereco.titulo}</h2>
            <ul>
              <li>
                <p>
                  <i className='end-info-icones'>
                    <GoLocation />
                  </i>
                  {endereco}
                </p>
              </li>
              <li>
                <a
                  href={rotaEmail}
                  target='_blank'
                  rel='noreferrer'
                  alt='Email - Hotel Le Canard'
                >
                  <i className='end-info-icones'>
                    <AiOutlineMail />
                  </i>
                  {email}
                </a>
              </li>
              <li>
                <a
                  href={rotaTelefone}
                  target='_blank'
                  rel='noreferrer'
                  alt='Telefone - Hotel Le Canard'
                >
                  <i className='end-info-icones'>
                    <BsTelephone />
                  </i>
                  {telefone}
                </a>
              </li>
            </ul>
            <div className='end-btn-wrap-contato'>
              <Link to='/contato' alt='Entre em contato'>
                {InfoEndereco.BtnContato}{' '}
                <span className='end-icon-btn-contato'>
                  <IoIosArrowForward />
                </span>
              </Link>
            </div>
          </div>
        </section>
      ) : (
        <div className='flex flex-col w-full mb-3'>
          <div className='min-w-full '>
            <div
              title='Clique para endereço'
              rel='noreferrer'
              alt='Localização Hotel - Le Canard'
            >
              <iframe
                src={urlMaps}
                className='mapa'
                allowFullScreen
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
                title='Maps-Trianon'
              />
            </div>
          </div>
          <div className='flex flex-row items-center justify-center gap-5 pl-2 pr-2'>
            <div className='flex flex-col justify-center min-[768px]:pr-14'>
              <p className='text-md text-black font-bold font-roboto-flex pt-3'>
                {InfoEndereco.titulo}
              </p>
              <div className='flex pb-1 pt-3'>
                <FiMapPin
                  className='pr-2 pb-2 text-2xl max-[320px]:text-4xl'
                  style={{ color: '#808181' }}
                />
                <a
                  className='text-xs font-semibold font-roboto-flex text-black pt-1 text-left'
                  href='https://goo.gl/maps/vFpnWUvf42cFmNDz9'
                  target='_blank'
                  rel='noreferrer'
                >
                  Rua XV De Novembro, 2075 | Joinville/SC
                </a>
              </div>
              <div className='flex gap-2'>
                <FiMail className='text-2xl' style={{ color: '#808181' }} />
                <a
                  className='text-xs font-semibold font-roboto-flex text-black pt-1'
                  href='mailto:joinville@lecanard.com.br'
                >
                  joinville@lecanard.com.br
                </a>
              </div>
              <div className='flex gap-2 pt-1'>
                <FiPhone className='text-2xl' style={{ color: '#808181' }} />
                <a
                  href='tel:554734335033'
                  className='text-xs font-semibold font-roboto-flex text-black pt-1'
                >
                  47 3433-5033
                </a>
              </div>
            </div>
            <Link
              to='/contato'
              alt='Entre em contato'
              className='btn-book w-full max-w-38 max-h-12 rounded-md cursor-pointer mt-3'
              style={{ backgroundColor: '#0F1620' }}
            >
              <div className='btn-book-text font-roboto-flex text-sm'>
                {InfoEndereco.BtnContato}
              </div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Localizacao;
