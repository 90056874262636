import React, { useState, useEffect } from 'react';
import {
  GiCoffeeCup,
  GiWeightLiftingUp,
  GiTable,
  GiShower,
  GiSnowflake1,
} from 'react-icons/gi';
import { BiRestaurant, BiFridge, BiBed, BiFullscreen } from 'react-icons/bi';
import { CgScreen } from 'react-icons/cg';
import { FaWifi } from 'react-icons/fa';

// rotas

import BannerHotel from '../../components/BannerHotel';
import QuartoR from '../../components/Quartos/quartosRight';
import QuartoL from '../../components/Quartos/QuartoLeft';
import CafedaManha from '../../components/CafedaManha';
import Restaurante from '../../components/Restaurante';
import Estacionamento from '../../components/Estacionamento';
import Localizacao from '../../components/localizacao';
import GroupIcons from '../../components/GroupIcons';
import EnvIcone from '../../components/EnvIcone';
import HomeIndex from '../../components/homesIndex/homesIndex';

// imagens
import Banner from '../../assets/BannerJoinville.webp';
import RestauranteImg from '../../assets/RestauranteLeCanardi.webp';
import ImagemEstacionamento from '../../assets/Estacionamento_Hotel_Le_Canard.webp';
import MapaImage from '../../assets/Localizacao_Le_Canard_Joinville.webp';
import homeJoinville from '../../assets/homeJoinville.png';

import BannerQuartoRight from '../../assets/quarto-joinville.webp';

const UnJoinville = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const Seo = {
    titulo: 'Hotel Le Canard - Joinville',
    altPadrao: 'Hotel Le Canard - Joinville',
    altBannerLeft: 'Cama de Casal - Hotel Le Canard - Joinville',
    describe:
      'O Le Canard Joinville está estrategicamente localizado na entrada principal da cidade, na Rua XV de Novembro, 2.075, acesso facilitado aos executivos e turistas que chegam a Joinville, por isso é eleito como um dos hotéis mais bem situados do município.',
  };

  const infoProdutos = {
    descriptionIcone01: 'TV LED 32’',
    descriptionIcone02: '2 Camas Solteiro',
    descriptionIcone03: '17 m²',
    descriptionIcone04: 'Cama Queen',
    descriptionIcone05: 'Cama Solteiro King',
    descriptionIcone06: 'TV LED 42’',
    descriptionIcone07: '2 Camas Solteiro King',
    descriptionIcone08: '19 m²',
    descriptionIcone09: 'Hidromassagem',
    descriptionIcone10: 'Espelho Maquiagem',
    botaoReserva: 'Quero reservar',
    botaoFotos: 'Ver mais fotos',
  };
  const infoProdutosQR = {
    titulo: 'Executivo Twin',
    titulo02: 'Duplo Twin',
    titulo03: 'Superior Duplo',
    titulo04: 'Ala de quartos majoritariamente femininos',
    describe:
      'O Executivo Twin conta com um ambiente aconchegante, no qual possui 1 cama queen, TV LED 32 polegadas, frigobar sempre abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
    describe02:
      'O Duplo Twin conta com um ambiente aconchegante, no qual possui 1 cama de solteiro king, TV LED 32 polegadas, frigobar sempre abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
    describe03:
      'O Superior Duplo está situado em uma nova ala do hotel, contando com uma decoração moderna, televisão maior, melhor isolamento acústico, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
    describe04:
      'Pensando no bem estar e na privacidade feminina, o Le Canard possui uma ala de quartos exclusiva para mulheres. Além de tudo que é oferecido nos quartos Superiores, esses ainda contam com um espelho de quase 2m, chapinha e um secador super potente.',
  };
  const infoProdutosQL = {
    titulo: 'Executivo Duplo',
    titulo02: 'Superior Twin',
    titulo03: 'Suíte Superior',
    descricao:
      'O Executivo Duplo conta com um ambiente aconchegante, no qual possui 1 cama queen, TV LED 32 polegadas, frigobar sempre abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
    descricao02:
      'O Superior Twin está situado em uma nova ala do hotel, contando assim com uma decoração mais moderna, televisão maior, um melhor isolamento acústico, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
    descricao03:
      'A Suíte Superior está situada em uma nova ala do hotel, contando com uma decoração moderna, televisão maior, melhor isolamento acústico, uma banheira com hidromassagem, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
  };

  const InfoIconesJoinville = {
    info01: 'Café da manha',
    info02: 'Academia 24h',
    info03: 'Wi-fi',
    info04: 'Restaurante',
    info05: 'Frigobar',
    info06: 'Mesa de Trabalho',
    info07: 'Duchas com pressurizador individual',
    info08: 'Ar condicionado split quente e frio',
  };
  const InfoLocalizacao = {
    endereco: 'Rua XV De Novembro, 2075 | Joinville/SC',
    email: 'joinville@lecanard.com.br',
    telefone: '47 3433-5033 ',
  };

  return (
    <>
      {windowWidth >= 769 ? (
        <>
          <BannerHotel
            banner={Banner}
            titulo={`${Seo.titulo}`}
            describe={`${Seo.describe}`}
          />
          <GroupIcons>
            <EnvIcone
              informacaodoIcone={`${InfoIconesJoinville.info01}`}
              NewIcons={<GiCoffeeCup />}
            />
            <EnvIcone
              informacaodoIcone={`${InfoIconesJoinville.info02}`}
              NewIcons={<GiWeightLiftingUp />}
            />
            <EnvIcone
              informacaodoIcone={`${InfoIconesJoinville.info03}`}
              NewIcons={<FaWifi />}
            />
            <EnvIcone
              informacaodoIcone={`${InfoIconesJoinville.info04}`}
              NewIcons={<BiRestaurant />}
            />
          </GroupIcons>
          <GroupIcons>
            <EnvIcone
              informacaodoIcone={`${InfoIconesJoinville.info05}`}
              NewIcons={<BiFridge />}
            />
            <EnvIcone
              informacaodoIcone={`${InfoIconesJoinville.info06}`}
              NewIcons={<GiTable />}
            />
            <EnvIcone
              informacaodoIcone={`${InfoIconesJoinville.info07}`}
              NewIcons={<GiShower />}
            />
            <EnvIcone
              informacaodoIcone={`${InfoIconesJoinville.info08}`}
              NewIcons={<GiSnowflake1 />}
            />
          </GroupIcons>

          {/* quartos  */}
          <QuartoR
            titulo={`${infoProdutosQR.titulo}`}
            descricao={`${infoProdutosQR.describe}`}
            btnReserva={`${infoProdutos.botaoReserva}`}
            btnFotos={`${infoProdutos.botaoFotos}`}
            alt={`${Seo.altPadrao}`}
            imagem={BannerQuartoRight}
            icons={[
              {
                info: `${infoProdutos.descriptionIcone01}`,
                icone: <CgScreen />,
              },
              { info: `${infoProdutos.descriptionIcone04}`, icone: <BiBed /> },
              {
                info: `${infoProdutos.descriptionIcone03}`,
                icone: <BiFullscreen />,
              },
            ]}
            Banner1={BannerQuartoRight}
            Banner2={BannerQuartoRight}
            Banner3={BannerQuartoRight}
          />
          {/* Outros QuartoR e QuartoL componentes... */}
          <CafedaManha imgSrc={RestauranteImg} />
          <Restaurante />
          <Estacionamento
            imgSrc={ImagemEstacionamento}
            descricao={
              'O hotel possui um estacionamento próprio privativo, com ampla quantidade de vagas, inclusive para ônibus.'
            }
          />
          <Localizacao
            email={`${InfoLocalizacao.email}`}
            endereco={`${InfoLocalizacao.endereco}`}
            telefone={`${InfoLocalizacao.telefone}`}
            imgSrc={MapaImage}
            urlMaps={'https://www.google.com/maps/embed?pb=...'}
          />
        </>
      ) : (
        <HomeIndex
          Image={homeJoinville}
          TextTittle={'Hotel Le Canard - Joinville'}
          TextLabel={
            'O Le Canard Joinville está estrategicamente localizado na entrada principal da cidade, na Rua XV de Novembro, 2075. Acesso facilitado aos executivos e turistas que chegam a Joinville, por isso é eleito como um dos hotéis mais bem situados do município.'
          }
        />
      )}
    </>
  );
};

export default UnJoinville;
