import React, { useState } from 'react'
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { FaRegBuilding, FaRegUser } from 'react-icons/fa';
import InputDate from '../InputDate';
import InputComboBox from '../InputComboBox';

import './styles.css';

export default function Checkin() {

    const hotels = [
        { value: '5c646d5be74ff237e458f09c', label: 'Joinville' },
        { value: '5dc55652ab41d41f8063cf10', label: 'Lages' },
    ];

    const adults = [
        { value: '1', label: '1 Adulto' },
        { value: '2', label: '2 Adultos' },
    ];

    const childen = [
        { value: '0', label: '0 Crianças' },
        { value: '1', label: '1 Criança' },
    ];

    const [selectedHotel, setSelectedHotel] = useState(hotels[0])
    const [selectedAdult, setSelectedAdult] = useState(adults[0])
    const [selectedChild, setSelectedChild] = useState(childen[0])
    const [checkinDate, setCheckinDate] = useState(new Date())
    const [checkoutDate, setCheckOutDate] = useState(new Date(checkinDate.getFullYear(), checkinDate.getMonth(), checkinDate.getDate() + 1))

    const [openMenu, setOpenMenu] = useState(false)
    const [openCheckin, setOpenCheckin] = useState(false)
    const [openHoteis, setOpenHoteis] = useState(false)

    const toggleMenu = () => {
        setOpenMenu(!openMenu)
    }

    const toggleCheckin = () => {
        setOpenCheckin(!openCheckin)
    }

    const returnDate = (val) => {
        return `${val.getFullYear()}-${val.getMonth()}-${val.getDate()}`
    }

    const bookHotel = () => {
        if (checkinDate > checkoutDate) {
            alert('Selecione uma data de check-out inferior à data de check-in')
            return
        }

        window.open(`https://hbook.hsystem.com.br/booking?companyId=${selectedHotel.value}&adults=${selectedAdult.value}&children=${selectedChild.value}&checkin=${returnDate(checkinDate)}&checkout=${returnDate(checkoutDate)}`)
    }

    return (
        <>
            {
                window.innerWidth < 1000 ?
                    <>
                        <div className='header-checkin' style={{ height: openCheckin ? '225px' : '60px' }} >
                            <div className='header-checkin-accordion' style={{ display: openCheckin ? 'flex' : 'none', flexDirection: 'column', height: '0px' }} >

                                <div className='double-item double-margin-mobile gap-3 ' >
                                    <InputComboBox label={'hotel Le Canard'} options={hotels} setState={setSelectedHotel} icon={< FaRegBuilding className='header-item-icon' />} />
                                    <InputDate state={checkinDate} setState={setCheckinDate} label={'check-in'} min={new Date()} />
                                </div>

                                <div className='double-item double-margin-mobile gap-3' >
                                    <InputDate state={checkoutDate} setState={setCheckOutDate} label={'check-out'} min={new Date(checkinDate.getFullYear(), checkinDate.getMonth() - 1, checkinDate.getDate() + 1)} />
                                    <InputComboBox label={'adultos'} options={adults} setState={setSelectedAdult} icon={< FaRegUser className='header-item-icon' />} />
                                </div>

                                <div className='double-item double-margin-mobile gap-3' >
                                    <InputComboBox label={'crianças'} options={childen} setState={setSelectedChild} icon={< FaRegUser className='header-item-icon' />} />
                                    <div className='btn-book w-full min-w-32 max-w-40 min-[1025px]:max-w-40 rounded-md p-2 cursor-pointer' style={{ backgroundColor: '#0F1620' }}>
                                        <div className='btn-book-text font-roboto-flex text-lg' onClick={() => bookHotel()} >Reservar</div>
                                    </div>
                                </div>

                            </div>

                            <div className='header-toggle-checkin' onClick={() => toggleCheckin()} >
                                {
                                    openCheckin ?
                                        <>
                                            <BsChevronCompactUp className='header-toggle-checkin-icon' />
                                        </>
                                        :
                                        <>
                                            <p className='header-toggle-checkin-text' >CLIQUE AQUI E FAÇA SUA RESERVA</p>
                                            <BsChevronCompactDown className='header-toggle-checkin-icon' />
                                        </>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='header-checkin gap-2' style={{ paddingBottom: '20px' }} >

                            <div className='double-item double-margin-mobile gap-3' >
                                <InputComboBox label={'hotel Le Canard'} options={hotels} setState={setSelectedHotel} icon={< FaRegBuilding className='header-item-icon' />} />
                                <InputDate state={checkinDate} setState={setCheckinDate} label={'check-in'} min={new Date()} />
                            </div>

                            <div className='double-item double-margin-mobile gap-3' >
                                <InputDate state={checkoutDate} setState={setCheckOutDate} label={'check-out'} min={new Date(checkinDate.getFullYear(), checkinDate.getMonth() - 1, checkinDate.getDate() + 1)} />
                                <InputComboBox label={'adultos'} options={adults} setState={setSelectedAdult} icon={< FaRegUser className='header-item-icon' />} />
                            </div>

                            <div className='double-item double-margin-mobile gap-3' >
                                <InputComboBox label={'crianças'} options={childen} setState={setSelectedChild} icon={< FaRegUser className='header-item-icon' />} />
                                <div className='btn-book w-full min-w-32 max-w-40 min-[1025px]:max-w-40 rounded-md p-2 cursor-pointer' style={{ backgroundColor: '#0F1620' }}>
                                    <div className='btn-book-text font-roboto-flex text-lg' onClick={() => bookHotel()} >Reservar</div>
                                </div>
                            </div>

                        </div>
                    </>
            }
        </>
    )
}
