import React from 'react';
import { useLocation } from 'react-router-dom'; // Importe o useLocation
import CarrouselMobile from '../CarrouselMobile';
import LandingContent from '../hotelLanding/landingContent';
import homeIndex1 from '../../assets/homeIndex1.png';
import Localizacao from '../../components/localizacao';
import MapaImage from '../../assets/Localizacao_Le_Canard_Joinville.webp';
import cafeLages from '../../assets/cafeLages.png';
import estacionamentoLages from '../../assets/estacionamentoLages.png';

const HomeIndex = ({ Image, TextTittle, TextLabel }) => {
    const location = useLocation(); // Use o hook useLocation para obter o caminho atual

    const InfoLocalizacao = {
        endereco: 'Rua XV De Novembro, 2075 | Joinville/SC',
        email: 'joinville@lecanard.com.br',
        telefone: '47 3433-5033 ',
    };

    // Condicional para verificar se a URL é '/joinville'
    const showLandingJoinville = location.pathname === '/joinville';
    const showLandingLages = location.pathname === '/lages';

    return (
        <div className='flex flex-col w-full items-center text-center pt-12'>
            <img className='w-full' src={Image} alt="Banner" />
            <div className='flex flex-col items-center text-center min-w-64 max-w-64 pt-2 pb-2'>
                <p className='text-sm text-black font-bold font-roboto-flex'>{TextTittle}</p>
                <p className='text-xs text-black font-medium font-roboto-flex'>{TextLabel}</p>
            </div>

            <CarrouselMobile />

            {showLandingJoinville && (
                <>
                    <LandingContent
                        direction="left"
                        Imagem={homeIndex1}
                        TextTittle={"Café da manhã"}
                        TextLabel={"O mais completo café da manhã da categoria. Dispomos de um completo café da manhã com opções variadas e estação Light."}
                    />
                    <LandingContent
                        direction="right"
                        Imagem={homeIndex1}
                        TextTittle={"Restaurante"}
                        TextLabel={"O Le Canard Joinville conta com restaurante próprio, sendo uma cozinha elaborada com certificação da Sebrae."}
                    />
                    <LandingContent
                        direction="left"
                        Imagem={homeIndex1}
                        TextTittle={"Estacionamento"}
                        TextLabel={"O hotel possui um estacionamento próprio privativo, com ampla quantidade de vagas, inclusive para ônibus."}
                    />
                </>
            )}


            {showLandingLages && (
                <>
                    <LandingContent
                        direction="left"
                        Imagem={cafeLages}
                        TextTittle={"Café da manhã"}
                        TextLabel={"O mais completo café da manhã da categoria. Dispomos de um completo café da manhã com opções variadas e estação Light."}
                    />
                    <LandingContent
                        direction="right"
                        Imagem={estacionamentoLages}
                        TextTittle={"Estacionamento"}
                        TextLabel={"O hotel possui um estacionamento próprio privativo, com ampla quantidade de vagas, inclusive para ônibus."}
                    />
                </>
            )}

            {showLandingLages && (
                <>
                    <Localizacao
                        email={InfoLocalizacao.email}
                        endereco={InfoLocalizacao.endereco}
                        telefone={InfoLocalizacao.telefone}
                        imgSrc={MapaImage}
                        urlMaps={
                            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14115.91898580794!2d-50.3185858!3d-27.8103943!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x698e6ce8c974a15a!2sHotel%20Le%20CanarD%20Lages!5e0!3m2!1spt-BR!2sbr!4v1665496858269!5m2!1spt-BR!2sbr'
                        }
                    />
                </>
            )}

            {showLandingJoinville && (
                <>
                    <Localizacao
                        email={InfoLocalizacao.email}
                        endereco={InfoLocalizacao.endereco}
                        telefone={InfoLocalizacao.telefone}
                        imgSrc={MapaImage}
                        urlMaps={
                            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3576.9090284345125!2d-48.86530842458162!3d-26.297048977019493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94deb0179ce803f3%3A0x341b47295c828b1f!2sHot%C3%A9is%20Le%20Canard%20Joinville!5e0!3m2!1spt-BR!2sbr!4v1721658176042!5m2!1spt-BR!2sbr'
                        }
                    />
                </>
            )}

        </div>
    );
};

export default HomeIndex;
