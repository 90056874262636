import React, { useState, useEffect, memo } from 'react'
import { LogoHeader } from '../../assets/icons';
import { useLocation } from "react-router-dom"
import { Link } from 'react-router-dom';
import logoBranca from '../../assets/logoBranca.png';
import SelectHotel from '../SelectHotel';
import Checkin from '../Checkin';
import { FaAngleDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function Header() {

  const location = useLocation();

  const hotels = [
    { value: '5c646d5be74ff237e458f09c', label: 'Joinville' },
    { value: '5dc55652ab41d41f8063cf10', label: 'Lages' },
  ];

  const [winWidth, setwinWidth] = useState(window.innerWidth);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleMenuOpen = (event) => {
    if (anchorEl && anchorEl === event.currentTarget) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    function handleResize() {
      setwinWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  const handleClick = (path) => {
    navigate(path);
  };


  const textDivClass = "text-white font-medium text-xs hover:underline font-roboto-flex min-[768px]:text-xl";

  return (
    <>
      {window.innerWidth >= 1000 ? (
        <div className='header-container' style={{ width: `${winWidth}px` }} >
          <div className='header-menus' >

            <div className='menu-mobile-container' onClick={toggleMenu} >
              <div className='menu-mobile-bar' style={openMenu ? { transform: 'rotate(45deg)', top: '15px' } : { transform: 'rotate(0deg)', top: '0' }} />
              <div className='menu-mobile-bar' style={{ display: openMenu ? 'none' : 'block' }} />
              <div className='menu-mobile-bar' style={openMenu ? { transform: 'rotate(-45deg)', top: '-8px' } : { transform: 'rotate(0deg)', top: '0' }} />
            </div>

            <div className='header-logo'>
              <Link to="/" alt="Hotel - Le Canard">
                <LogoHeader alt="Le Canard - Hotel em Santa Catarina" />
              </Link>
            </div>

            <div className='header-row' style={{ height: openMenu ? '375px' : window.innerWidth < 1000 ? '0px' : '50px' }} >

              <Link className='header-item' to="/" alt="Home - Le Canard ">Início</Link>
              <SelectHotel options={hotels} >
                <div className='header-item' style={{ marginRight: '5px', marginTop: '15px' }} >Hotéis</div>
              </SelectHotel>

              <Link className='header-item' to="/lazer" alt="Lazer - Le Canard ">Lazer</Link>
              <Link className='header-item' to='/eventos' alt="Eventos - Le Canard ">Eventos</Link>
              <Link className='header-item' to="/cliente" alt="Clientes - Le Canard ">Cliente Habitue</Link>
              <Link className='header-item' to="/contato" alt="Contato - Le Canard ">Contato</Link>
            </div>
          </div>

          {location.pathname === '/' && <Checkin />}

        </div>
      ) : (
        <div className="fixed w-full z-50 ps-2 pe-0" style={{ backgroundColor: '#45454D' }}>
          <div className="container mx-auto py-2 flex justify-between items-center ps-2 pe-2">
            <div className="flex items-center">
              <a href="/" className="flex items-center text-white">
                <img src={logoBranca} alt="Le Canard - Logo" className="max-w-9 h-auto mr-2 min-[768px]:max-w-14" />
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <a href="/" className={textDivClass}>Início</a>
              <div className="relative">
                <div className="flex items-center text-white font-medium text-xs hover:underline font-roboto-flex min-[768px]:text-xl" onClick={handleMenuOpen}>
                  Hotéis <FaAngleDown className="ml-1 text-white text-xs min-[768px]:text-xl" />
                </div>
                {anchorEl && (
                  <div className="absolute mt-1 py-2 bg-white rounded shadow-lg w-50 z-50">
                    {hotels.map((hotel) => (
                      <button
                        key={hotel.value}
                        onClick={() => handleNavigation(`/${hotel.label.toLowerCase()}`)}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full"
                      >
                        {hotel.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <button className={textDivClass} onClick={() => handleClick('/lazer')}>
                Lazer
              </button>
              <button className={textDivClass} onClick={() => handleClick('/eventos')}>
                Eventos
              </button>
              <button className={textDivClass} onClick={() => handleClick('/cliente')}>
                Habitue
              </button>
              <button className={textDivClass} onClick={() => handleClick('/contato')}>
                Contato
              </button>
            </div>
          </div>

          {location.pathname === '/' && (
            <>
              <p className="text-white font-bold ml-4 mt-2 font-roboto-flex">Reserve sua próxima estadia</p>
              <Checkin />
            </>
          )}
        </div>

      )}
    </>
  );
}

export default memo(Header)