import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import camaPopUp from '../../assets/camaPopUp.png';

const ActionBox = ({ onClose }) => {
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();

    const handleClickOutside = (e) => {
        if (e.target.classList.contains('bg-gray-900')) {
            onClose();
            setIsVisible(false);
        }
    };

    const handleJoinville = () => {
        navigate('/joinville');
    };

    const handleLages = () => {
        navigate('/lages');
    };


    return (
        <div className={`fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50 ${isVisible ? '' : 'hidden'}`} onClick={handleClickOutside}>
            <div className="bg-[#D9D9D9] p-8 rounded-lg shadow-lg flex items-center flex-col">
                <img src={camaPopUp} className='max-w-28' alt="Cama Icon" />
                <p className='text-xl font-bold font-roboto-flex mt-2'>Para onde você quer ir?</p>
                <p className='text-md font-roboto-flex mb-5'>Escolha onde você quer ir</p>
                <div className="flex flex-row gap-4 justify-center">
                    <button
                        className='w-32 text-md font-bold font-roboto-flex rounded-lg shadow-md focus:outline-none transition-colors min-h-12 text-white  bg-[#45454D]'
                        type='submit' onClick={handleJoinville}
                    >
                        <div
                            className="text-md font-bold font-roboto-flex"
                        >
                            Joinville
                        </div>
                    </button>
                    <button
                        className='w-32 text-md font-bold font-roboto-flex rounded-lg shadow-md focus:outline-none transition-colors min-h-12 text-white  bg-[#45454D]'
                        type='submit' onClick={handleLages}
                    >
                        <div
                            className="text-md font-bold font-roboto-flex"
                        >
                            Lajes
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ActionBox;
