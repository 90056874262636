import React, { memo } from 'react'
import { RiInstagramFill, RiFacebookBoxFill, RiMapPinLine } from 'react-icons/ri';
import { FiMail, FiPhone, FiMapPin } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import './styles.css';
import logoBranca from '../../assets/logoBranca.png';
import instaLogo from '../../assets/instaLogo.png';
import faceLogo from '../../assets/faceLogo.png';

import { LogoInteira } from '../../assets/icons';

function Footer() {

    const infoFooter = {
        instagramLink: 'https://www.instagram.com/lecanardhoteis/',
        facebookLink: 'https://www.facebook.com/lecanardhoteis',
    }

    return (
        <>
            {
                window.innerWidth > 1000 ?
                    <>
                        <div className='footer-container' >

                            <div className='footer-row' style={{ marginLeft: "auto", marginRight: "auto" }} >
                                <div className='footer-item' >
                                    <div className='footer-item-icon' >
                                        <Link to="/">
                                            <LogoInteira />
                                        </Link>
                                    </div>
                                    <div className='row' >
                                        <a className='footer-btn-redes-sociais' href={`${infoFooter.instagramLink}`} target="blank">
                                            <RiInstagramFill className='footer-social-icon' style={{ marginLeft: "auto" }} />
                                        </a>
                                        <a className='footer-btn-redes-sociais' href={`${infoFooter.facebookLink}`} target="blank">
                                            <RiFacebookBoxFill className='footer-social-icon' style={{ marginRight: "auto" }} />
                                        </a>
                                    </div>
                                </div>

                                <div className='footer-item' >
                                    <Link className='footer-item-title' to="/joinville">Joinville</Link>
                                    <div className='row' >
                                        <RiMapPinLine className='footer-item-icon-short' />
                                        <a className='footer-item-text' href="https://goo.gl/maps/vFpnWUvf42cFmNDz9" target="blank">Rua XV De Novembro, 2075 | Joinville/SC</a>
                                    </div>
                                    <div className='row' >
                                        <FiMail className='footer-item-icon-short' />
                                        <a className='footer-item-text' href="mailto:joinville@lecanard.com.br">joinville@lecanard.com.br</a>
                                    </div>
                                    <div className='row' >
                                        <FiPhone className='footer-item-icon-short' />
                                        <a href="tel:554734335033" className='footer-item-text' >47 3433-5033</a>
                                    </div>
                                </div>

                                <div className='footer-item' >
                                    <Link className='footer-item-title' to="/lages">Lages</Link>
                                    <div className='row' >
                                        <RiMapPinLine className='footer-item-icon-short' />
                                        <a className='footer-item-text' href="https://goo.gl/maps/uo6t58AjjXfa1AvRA" target="blank">Avenida Presidente Vargas, 255 | Lages/SC</a>
                                    </div>
                                    <div className='row' >
                                        <FiMail className='footer-item-icon-short' />
                                        <a className='footer-item-text' href="mailto:lages@lecanard.com.br">lages@lecanard.com.br</a>
                                    </div>
                                    <div className='row' >
                                        <FiPhone className='footer-item-icon-short' />
                                        <a className='footer-item-text' href="tel:554921018100">49 2101-8100</a>
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginLeft: "auto", marginRight: "auto" }} >
                                <div style={{ color: "#10213A", fontSize: "16px", marginBottom: "5px", paddingBottom: '10px' }} >&copy; 2024 VEGA IT - Todos os Direitos Reservados</div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='flex items-center min-h-44 flex-col gap-2 pt-3 min-[768px]:flex-row min-[768px]:justify-center' style={{ backgroundColor: "#45454D" }}>
                            <div className='max-[320px]:flex-1  flex-col justify-center items-center min-[768px]:pr-20'>
                                <img src={logoBranca} alt="Le Canard - Logo" className="max-h-20" />
                                <div className='flex justify-center mt-1 gap-5'>
                                    <img src={instaLogo} alt="Instagram Logo items-start" className="max-h-4" />
                                    <img src={faceLogo} alt="Facebook Logo" className="max-h-4" />
                                </div>
                            </div>


                            <div className='flex flex-row items-center pl-5 gap-3 pb-5 pt-2'>
                                <div className='flex-1 flex flex-col justify-center min-[768px]:pr-14'>
                                    <Link className='font-roboto text-white text-xl font-extrabold pb-2' to="/joinville">Joinville</Link>
                                    <div className='flex pb-1'>
                                        <FiMapPin className='pr-2 pb-2 text-2xl max-[320px]:text-3xl' style={{ color: "#808181" }} />
                                        <a className='text-xs hover:underline font-roboto-flex text-white' href="https://goo.gl/maps/vFpnWUvf42cFmNDz9" target="_blank" rel="noreferrer">Rua XV De Novembro, 2075 | Joinville/SC</a>
                                    </div>
                                    <div className='flex gap-2'>
                                        <FiMail className='text-sm' style={{ color: "#808181" }} />
                                        <a className='text-xs hover:underline font-roboto-flex text-white' href="mailto:joinville@lecanard.com.br">joinville@lecanard.com.br</a>
                                    </div>
                                    <div className='flex gap-2 pt-1'>
                                        <FiPhone className='text-sm' style={{ color: "#808181" }} />
                                        <a href="tel:554734335033" className='text-xs hover:underline font-roboto-flex text-white pt-1'>47 3433-5033</a>
                                    </div>
                                </div>

                                <div className='flex-1 flex flex-col justify-center '>
                                    <Link className='font-roboto text-white text-xl font-extrabold pb-2' to="/lages">Lages</Link>
                                    <div className='flex pb-1'>
                                        <FiMapPin className='pr-2 pb-2 text-2xl max-[320px]:text-3xl' style={{ color: "#808181" }} />
                                        <a className='text-xs hover:underline font-roboto-flex text-white' href="https://goo.gl/maps/vFpnWUvf42cFmNDz9" target="_blank" rel="noreferrer">Rua XV De Novembro, 2075 | Joinville/SC</a>
                                    </div>
                                    <div className='flex gap-2'>
                                        <FiMail className='text-sm' style={{ color: "#808181" }} />
                                        <a className='text-xs hover:underline font-roboto-flex text-white' href="mailto:joinville@lecanard.com.br">joinville@lecanard.com.br</a>
                                    </div>
                                    <div className='flex gap-2 pt-1'>
                                        <FiPhone className='text-sm' style={{ color: "#808181" }} />
                                        <a href="tel:554734335033" className='text-xs hover:underline font-roboto-flex text-white pt-1'>47 3433-5033</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
            }
        </>
    )
}

export default memo(Footer)