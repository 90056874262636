import React, { useState } from 'react';
import './style.css';
import Banner from '../../assets/ImageForm.webp';

const Formulario = () => {
  const ConteudoForm = {
    RadioOne: 'LeCanard Joinville',
    RadioTwo: 'LeCanard Lages',
  };

  const [selectedHotel, setSelectedHotel] = useState('');

  const handleHotelSelect = (value) => {
    setSelectedHotel(value);
  };

  const inputClassName =
    'border-2 border-solid border-black rounded-lg text-black placeholder-gray-500';

  return (
    <>
      {window.innerWidth >= 769 ? (
        <main className='form-wrapper'>
          <div className='form-text-wrap-decoration'>
            <img src={Banner} alt='Contato - Hotel Le Canard' />
          </div>
          <div className='form-wrapper-right'>
            <div className='form-escopo'>
              <form
                action='https://formsubmit.co/comercial.joinville@lecanard.com.br'
                method='POST'
              >
                <input
                  type='hidden'
                  name='_cc'
                  value='lages@lecanard.com.br,admlages@lecanard.com.br,recepcaolages@lecanard.com.br,reservalages@lecanard.com.br,eventos.lages@lecanard.com.br'
                />
                <div className='form-radio-box'>
                  <input type='radio' name='hotel' value='joinville' required />
                  <p>{ConteudoForm.RadioOne}</p>
                  <input
                    id='form-inputTwo'
                    type='radio'
                    name='hotel'
                    value='lages'
                    required
                  />
                  <p>{ConteudoForm.RadioTwo}</p>
                </div>
                <div className='form-wrap-inputs'>
                  <p className='text-md font-bold font-roboto-flex'>Nome*</p>
                  <input
                    type='text'
                    name='Nome'
                    placeholder='Digite seu nome'
                    required
                  />
                  <p className='form-describe-inputs'>E-mail*</p>
                  <input
                    type='email'
                    name='E-mail'
                    placeholder='Digite seu e-mail'
                    required
                  />
                  <p className='form-describe-inputs'>Assunto*</p>
                  <input
                    type='text'
                    name='Assunto'
                    placeholder='Sobre o que deseja conversar?'
                    required
                  />
                  <p className='form-describe-inputs'>Mensagem*</p>
                  <textarea
                    name='Mensagem'
                    placeholder='Escreva sua mensagem'
                    required
                  />
                  <input type='hidden' name='_captcha' value='false' />
                  <input
                    type='hidden'
                    name='_next'
                    value='http://lecanardprototipo.aprovacao.vegapix.com.br/'
                  />
                  <button className='form-btn' type='submit'>
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      ) : (
        <div className='form-wrapper mb-5'>
          <div className='flex items-center justify-center'>
            <div className='pt-5'>
              <form
                action='https://formsubmit.co/comercial.joinville@lecanard.com.br'
                method='POST'
              >
                <input type='hidden' name='_cc' value='' />
                <div className='flex flex-row justify-center gap-3'>
                  <button
                    type='button'
                    onClick={() => handleHotelSelect('joinville')}
                    className={`text-md font-bold font-roboto-flex rounded-lg shadow-md focus:outline-none transition-colors min-h-8 w-full${
                      selectedHotel === 'joinville'
                        ? ' text-white bg-[#0F1620]'
                        : ' text-black '
                    }`}
                  >
                    Joinville
                  </button>
                  <button
                    type='button'
                    onClick={() => handleHotelSelect('lages')}
                    className={`w-full min-w-28 text-md font-bold font-roboto-flex rounded-lg shadow-md focus:outline-none transition-colors${
                      selectedHotel === 'lages'
                        ? ' text-white bg-[#0F1620]'
                        : ' text-black '
                    }`}
                  >
                    Lages
                  </button>
                </div>
                <div className='form-wrap-inputs pt-3'>
                  <p className='text-md font-bold font-roboto-flex'>Nome</p>
                  <input
                    type='text'
                    name='Nome'
                    placeholder='Digite seu nome'
                    required
                    className={inputClassName}
                  />
                  <p className='text-md font-bold font-roboto-flex'>E-mail</p>
                  <input
                    type='email'
                    name='E-mail'
                    placeholder='Digite seu e-mail'
                    required
                    className={inputClassName}
                  />
                  <p className='text-md font-bold font-roboto-flex'>Assunto</p>
                  <input
                    type='text'
                    name='Assunto'
                    placeholder='Sobre o que deseja conversar?'
                    required
                    className={inputClassName}
                  />
                  <p className='text-md font-bold font-roboto-flex'>Mensagem</p>
                  <textarea
                    name='Mensagem'
                    placeholder='Escreva sua mensagem'
                    required
                    className={inputClassName}
                  />
                  <input type='hidden' name='_captcha' value='false' />
                  <input
                    type='hidden'
                    name='_next'
                    value='http://lecanardprototipo.aprovacao.vegapix.com.br/'
                  />
                  <button
                    className='text-md font-bold font-roboto-flex rounded-lg shadow-md focus:outline-none transition-colors min-h-12 w-full text-white bg-[#0F1620]'
                    type='submit'
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Formulario;
