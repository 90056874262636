import React, { useState, memo } from 'react'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

import './style.css';

function Carousel(props) {

    const { children } = props

    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children.length)
    const [touchPosition, setTouchPosition] = useState(null)

    const next = () => {
        if (currentIndex < (length - 1)) {
            setCurrentIndex(prevState => prevState + 1)
        } else { setCurrentIndex(0) }
    }

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        } else { setCurrentIndex(length - 1) }
    }

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if (touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }


    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">

                <HiOutlineChevronLeft className="left-arrow" onClick={prev} />

                <div className="carousel-content-wrapper" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}  >
                    <div className="carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }} >
                        {children}
                    </div>
                </div>

                <div className='carousel-selector-container' >
                    {
                        children.map((i, index) => {
                            return (
                                <div key={index} onClick={() => setCurrentIndex(index)} className={currentIndex === index ? 'carousel-selector-fill' : 'carousel-selector-empty'} />
                            )
                        })
                    }
                </div>

                <HiOutlineChevronRight className="right-arrow" onClick={next} />
            </div>
        </div>
    )
}

export default memo(Carousel)